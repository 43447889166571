.Slot {
  display: flex;
  flex-basis: 100%;
  flex-grow: 0;
  flex-wrap: wrap;
}

.FullWidth {
  flex-basis: 100%;
}

.HalfWidth {
  flex-basis: 50%;
}

.ThirdWidth {
  flex-basis: calc(100%/3);
}

.QuarterWidth {
  flex-basis: 25%;
}